import React from 'react';
import { createStyles, withStyles, CircularProgress } from '@material-ui/core';
import { GET_LIST, Query } from 'react-admin';

const styles = (theme) =>
  createStyles({
    root: {
      maxWidth: theme.spacing(16),
      maxHeight: theme.spacing(6),
    },
  });

const ETSY_URL = `${process.env.REACT_APP_MEDIA_PROVIDER_URL}/market-service-providers/ETSY/logo.png`;

const ServiceLogo = ({ classes, resource, code }) => (
  <Query type={GET_LIST} resource={resource}>
    {({ data, loading, error }) => {
      if (loading) return <CircularProgress />;
      if (error) return <p>error</p>;

      const provider = data.find((p) => p.code === code);

      return (
        <img
          className={classes.root}
          src={code === 'ETSY' ? ETSY_URL : provider?.logoUrl}
          alt={provider?.fullName}
        />
      );
    }}
  </Query>
);

export default withStyles(styles)(ServiceLogo);
